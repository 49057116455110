import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import Eth from "ethjs-query";
import { AddBEvent, TagDetailQuery, UpdateBEvent } from "../../api";
import JsonEditor from "../JsonEditor";
import * as Web3 from "web3"; // Import the web3 library
import Modal from 'react-modal';
const web3 = new Web3(window.ethereum); // Initialize web3 with MetaMask's provider
const ethereum = window.ethereum;
const customStyles = {
   
  };
const CustomModal = ({ isOpen, onRequestClose, editData, btid ,setEditing}) => {
  const [data, setData] = useState(editData.payload);
  const [error, setError] = useState("");

  useEffect(() => {
    if (editData && editData.id) {
      handleJSONEdtior(JSON.parse(editData.payload));
    }
  }, [editData]);
 

  const [updateBEvent] = useMutation(UpdateBEvent, {
    update(cache, { data: { updateBevent } }) {
      const { tag } = cache.readQuery({
        query: TagDetailQuery,
        variables: { btid },
      });

      // Find and update the specific bevent in the tag's bevents array
      const updatedBevents = tag.bevents.map((bevent) => {
        if (bevent.id === editData.id) {
          return updateBevent;
        }
        return bevent;
      });

      cache.writeQuery({
        query: TagDetailQuery,
        data: { tag: { ...tag, bevents: updatedBevents } },
      });
    },
  });

  const handleJSONEdtior = (newData) => {
    setData(newData);
  };
 

  const handleUpdateSubmit = async (payload) => {
    setError("");
    if (!payload) {
      setError("Payload is empty or invalid.");
      return;
    }

    const msg = web3.utils.toHex(payload);
    const from = ethereum.selectedAddress;

    if (!from) {
      connect();
      setError("Please connect MetaMask.");
      return;
    }

    const eth = new Eth(ethereum);
    try {
      const sig = await eth.personal_sign(msg, from);

      const recovered = await eth.personal_ecRecover(msg, sig);

      if (recovered.toLowerCase() !== from.toLowerCase()) {
        console.dir({ recovered, from });
      } else {
        // Pass the id of the bevent to update
        updateBEvent({
          variables: {
            id: parseInt(editData.id),
            btid: btid || "",
            from,
            hashfn: editData.hashfn.toString(),
            payload: JSON.stringify(payload),
            sig,
            type: editData.type.toString(),
          },
        });
        setEditing(false)
        setData({});
      }
    } catch (error) {
      console.error("Error signing or recovering:", error);
      setError("Error signing or recovering: " + error.message);
    }
  };

  if (!web3) {
    return (
      <div>
        <p>Install Metamask to insert data</p>
      </div>
    );
  } else {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="custom-modal" // Apply custom class for modal styles
        overlayClassName="custom-modal-overlay" // Apply custom class for overlay styles
      >
        <div className="w-full">
          <div className="flex flex-wrap mb-6 -mx-3">
            <div className="w-full px-3">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="bevent-data"
              >
                Update data
              </label>

              <div className="w-full py-3 mb-3">
              <JsonEditor
                    value={ data
                    }
                    handleChange={(newData) => handleJSONEdtior(newData)}
                  />
              </div>

              <p className="text-xs italic text-red-600">{error}</p>

              <button
                onClick={() =>handleUpdateSubmit(data) }
                className="px-4 py-2 font-bold text-white bg-purple-500 rounded shadow hover:bg-purple-400 focus:shadow-outline focus:outline-none"
              >
                Update
              </button>

              <button
                onClick={onRequestClose}
                className="px-4 py-2 mx-2 font-bold text-white bg-purple-500 rounded shadow hover:bg-purple-400 focus:shadow-outline focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

export default CustomModal;

function connect() {
  if (typeof ethereum !== "undefined") {
    ethereum.enable().catch(console.error);
  }
}
