import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import Eth from "ethjs-query";
import { AddBEvent, TagDetailQuery, UpdateBEvent } from "../../api";
import JsonEditor from "../JsonEditor";
import * as Web3 from "web3"; // Import the web3 library

const web3 = new Web3(window.ethereum); // Initialize web3 with MetaMask's provider
const ethereum = window.ethereum;
export default function ({ btid, editData }) {
  const [data, setData] = useState(sampleData);
  const [error, setError] = useState("");

  useEffect(() => {
    if (editData && editData.id) {
      handleJSONEdtior(JSON.parse(editData.payload));
    }
  }, [editData]);
  const [addBEvent] = useMutation(AddBEvent, {
    update(cache, { data: { insertBevent } }) {
      const { tag } = cache.readQuery({
        query: TagDetailQuery,
        variables: { btid },
      });
      cache.writeQuery({
        query: TagDetailQuery,
        data: { tag: { ...tag, bevents: tag.bevents.concat([insertBevent]) } },
      });
    },
  });



  const handleJSONEdtior = (newData) => {
    setData(newData);
  };
  // payload must be json currently
  const handleSubmit = async (payload) => {
    setError("");

    // support nonjson payloads
    // let payload
    // try {
    // payload = JSON.parse(data)
    // } catch (err) {
    // setError(err.toString())
    // payload = data
    // }

    if (!payload) {
      setError("Payload is empty or invalid.");
      return;
    }

    const msg = web3.utils.toHex(payload); // Convert payload to JSON and then to hex
    const from = ethereum.selectedAddress; // Use MetaMask's selected address

    if (!from) {
      connect();
      setError("Please connect MetaMask.");
      return;
    }
    if (!from) connect();
    const eth = new Eth(ethereum);
    try {
      const sig = await eth.personal_sign(msg, from);

      var recovered = await eth.personal_ecRecover(msg, sig);

      if (recovered.toLowerCase() !== from.toLowerCase()) {
        console.dir({ recovered, from });
      } else {
        addBEvent({
          variables: {
            btid: btid || "",
            from: from,
            hashfn: "KECCAK256",
            payload: JSON.stringify(payload),
            sig: sig,
            type: btid ? "DATA" : "ACTIVATE",
          },
        });

        setData({});
      }
    } catch (error) {
      console.error("Error signing or recovering:", error);
      setError("Error signing or recovering: " + error.message);
    }
  };



  if (!web3) {
    return (
      <div>
        <p>Install Metamask to insert data</p>
      </div>
    );
  } else {
    return (
      <div className="w-full">
        <div className="flex flex-wrap mb-6 -mx-3">
          <div className="w-full px-3">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
              htmlFor="bevent-data"
            >
             Add data
            </label>

            <div className="w-full py-3 mb-3">
            <JsonEditor
                  value={data}
                  handleChange={(newData) => handleJSONEdtior(newData)}
                />
            </div>

            <p className="text-xs italic text-red-600">{error}</p>

            <button
              onClick={() => handleSubmit(data)
              }
              className="px-4 py-2 font-bold text-white bg-purple-500 rounded shadow hover:bg-purple-400 focus:shadow-outline focus:outline-none"
            >
           Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function connect() {
  if (typeof ethereum !== "undefined") {
    ethereum.enable().catch(console.error);
  }
}

const sampleData = {
  "@context": {
    "@vocab": "http://schema.org/",
    gr: "http://purl.org/goodrelations/v1#",
    pto: "http://www.productontology.org/id/",
    foaf: "http://xmlns.com/foaf/0.1/",
    xsd: "http://www.w3.org/2001/XMLSchema#",
    "foaf:page": {
      "@type": "@id",
    },
  },
  "@id": "https://blocktag.com/yikacbhwmy3lt6jn",
  "@type": "IndividualProduct",
  additionalType: "http://www.productontology.org/id/Cannabis_concentrate",
  name: "New Blocktag Tag Metadata",
  serialNumber: "yikacbhwmy3lt6jn",
  brand: "List brand here ", // 'ABC Extracts',
  category: "List Product category here", //'Agriculture > Cannabis > Concentrates',
  gtin: "asdf",
  manufacturer: "",
  model: "",
  productID: "",
  productionDate: "",
  weight: {
    value: "0.5",
    unitCode: "GRM",
  },
  identifier: "blocktagurl",
  image: "", //'https://images.squarespace-cdn.com/content/v1/5b311730b10598b0c12b0e19/1571995707906-DLOIZ3K8F9AS0P0RXTNK/ke17ZwdGBToddI8pDm48kFQQgP34qnCpeHaeAOzTt7pZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZamWLI2zvYWH8K3-s_4yszcp2ryTI0HqTOaaUohrI8PICHnXC1b9smDvYLPdL-DS7U1pkhCtl83kemXd5r3C5ngKMshLAGzx4R3EDFOm1kBS/Sweet_Wax_Hemp_Uncut_CBD_Vape_Wax_Cartridge__87318.1568222061.jpg?format=500w',
  mainEntityOfPage: "",
  url: "",
  subjectOf: [
    {
      "@type": "Event",
      name: "Genetic Information",
      startDate: "2018-08-21T20:00",
      endDate: "2018-08-21T20:00",
      about: "Genetic Information",
      description: "Seed genetic information recorded",
      eventStatus: "started",
      image: "image/of/manufacturing.jpg",
      organizer: {
        name: "XYZ Seed Labs",
        url: "company.com",
      },
      location: {
        "@type": "Place",
        name: "place1",
        url: "www.asdf.com/place1",
        address: "123 somewhere",
      },
    },
    {
      "@type": "Event",
      name: "Genetic Information",
      startDate: "2018-10-21T20:00",
      endDate: "2018-10-21T20:00",
      about: "Genetic Information",
      description: "Leaf genetic information recorded",
      eventStatus: "started",
      image: "image/of/manufacturing.jpg",
      organizer: {
        name: "XYZ Seed Labs",
        url: "company.com",
      },
      location: {
        "@type": "Place",
        name: "place1",
        url: "www.asdf.com/place1",
        address: "123 somewhere",
      },
    },
    {
      "@type": "Event",
      name: "Genetic Information",
      startDate: "2019-06-21T20:00",
      endDate: "2019-06-21T20:00",
      about: "Genetic Information",
      description: "Pre-distillation genetic information recorded",
      eventStatus: "started",
      image: "image/of/manufacturing.jpg",
      organizer: {
        name: "XYZ Seed Labs",
        url: "company.com",
      },
      location: {
        "@type": "Place",
        name: "place1",
        url: "www.asdf.com/place1",
        address: "123 somewhere",
      },
    },
    {
      "@type": "Event",
      name: "Packaged",
      startDate: "2019-07-21T20:00",
      endDate: "2019-07-21T20:00",
      about: "Packaged",
      description: "Packaged",
      eventStatus: "started",
      image: "image/of/manufacturing.jpg",
      organizer: {
        name: "ADC distributors",
        url: "company.com",
      },
      location: {
        "@type": "Place",
        name: "place1",
        url: "www.asdf.com/place1",
        address: "123 somewhere",
      },
    },
    {
      "@type": "Event",
      name: "Packaging verified",
      startDate: "2019-08-21T20:00",
      endDate: "2019-08-21T20:00",
      about: "Packaging verified",
      description: "Packaging verified",
      eventStatus: "started",
      image: "image/of/manufacturing.jpg",
      organizer: {
        name: "XYZ Seed Labs",
        url: "company.com",
      },
      location: {
        "@type": "Place",
        name: "place1",
        url: "www.asdf.com/place1",
        address: "123 somewhere",
      },
    },
    {
      "@type": "Event",
      name: "Bundled",
      startDate: "2019-09-21T20:00",
      endDate: "2019-09-21T20:00",
      about: "Bundled",
      description: "Bundled in Parcel 5678, Lot 1234",
      eventStatus: "started",
      image: "image/of/manufacturing.jpg",
      organizer: {
        name: "Shipperoo",
        url: "company.com",
      },
      location: {
        "@type": "Place",
        name: "place1",
        url: "www.asdf.com/place1",
        address: "123 somewhere",
      },
    },
    {
      "@type": "Event",
      name: "Retail",
      startDate: "2019-10-21T20:00",
      endDate: "2019-10-21T20:00",
      about: "On sale",
      description: "For retail sale",
      eventStatus: "started",
      image: "image/of/manufacturing.jpg",
      organizer: {
        name: "Med Women",
        url: "company.com",
      },
      location: {
        "@type": "Place",
        name: "place1",
        url: "www.asdf.com/place1",
        address: "123 somewhere",
      },
    },
  ],
  additionalProperty: [
    {
      "@type": "PropertyValue",
      name: "Cannabinoids",
      value: [
        {
          "@type": "PropertyValue",
          name: "THC",
          value: "16% (160 mg/g)",
        },
        {
          "@type": "PropertyValue",
          name: "CBD",
          value: "0.1% (1.3 mg/g",
        },
      ],
    },
    {
      "@type": "PropertyValue",
      name: "CA Compliance",
      value: [
        {
          "@type": "PropertyValue",
          name: "MICIC",
          value: "Pass",
        },
        {
          "@type": "PropertyValue",
          name: "RES",
          value: "Pass",
        },
        {
          "@type": "PropertyValue",
          name: "PES",
          value: "Pass",
        },
        {
          "@type": "PropertyValue",
          name: "FVI",
          value: "Pass",
        },
      ],
    },
    {
      "@type": "PropertyValue",
      name: "Genetics",
      value: [
        {
          "@type": "PropertyValue",
          name: "",
          value: "Dutch Passion Seeds 1992",
        },
        {
          "@type": "PropertyValue",
          name: "Shortcode",
          value: "#768e",
        },
        {
          "@type": "PropertyValue",
          name: "",
          value: "Shared with 1234 other products (view).",
        },
      ],
    },
  ],
};
