import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import { InquiryForm } from '../forms/ContactForm/inquiry'

export default function NavBar ({ toggleNavbar, isActive, appLive }) {
	const [loginVisible, setLoginVisible] = useState(false)
	const [error, setError] = useState('')

	// const toggleLoginVisible = () => setLoginVisible(!loginVisible)

	return (
		<>
			<nav className='z-50 flex flex-wrap items-center justify-between p-6 text-white bg-black'>
				<a href='https://www.blocktag.com'>
					<div className='flex items-center flex-shrink-0 mr-16 text-white'>
						<Logo className='w-8 h-8 mr-2 fill-current' viewBox='0 0 84.552 84.552' />
						<span className='text-sm font-semibold tracking-tight'>Blocktag</span>
					</div>
				</a>
				<div className='block lg:hidden' onClick={toggleNavbar}>
					<button className='flex items-center px-3 py-2 text-teal-200 border border-teal-400 rounded hover:text-white hover:border-white'>
						<svg className='w-3 h-3 fill-current' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><title>Menu</title><path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' /></svg>
					</button>
				</div>
				<div className={(isActive ? '' : 'hidden') + ' flex-grow block w-full text-gray-500 lg:flex lg:items-center lg:w-auto'}>
					<div className='text-sm lg:flex-grow'>
						<Link to='/' className='block mt-4 mr-8 lg:inline-block lg:mt-0 hover:text-white'>
							Tags
						</Link>
					</div>
					<div>
						<button onClick={() => setLoginVisible(true)} className='flex-grow px-8 py-1 my-4 text-sm text-white bg-teal-500 border-4 border-teal-500 rounded sm:my-0 hover:bg-teal-700 hover:border-teal-700'>
							Download App
						</button>
						{/* <a href='' className='inline-block px-4 py-2 mt-4 text-sm leading-none text-white border border-white rounded hover:border-transparent hover:text-teal-500 hover:bg-white lg:mt-0'>Download</a> */}

					</div>
				</div>
			</nav>

			{loginVisible
				? <div className='fixed inset-0 z-10 overflow-y-auto'>
					<div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
						{/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
          --> */}
						<div className='fixed inset-0 transition-opacity' aria-hidden='true'>
							<div className='absolute inset-0 bg-gray-500 opacity-75' />
						</div>

						{/* }<!-- This element is to trick the browser into centering the modal contents. --> */}
						<span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>
						{/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      --> */}
						<div className='inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full' role='dialog' aria-modal='true' aria-labelledby='modal-headline'>
							<div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
								<div className='sm:flex sm:items-start'>
									<div className='flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10'>
										{/* <!-- Heroicon name: exclamation --> */}
										<svg className='w-6 h-6 text-teal-400' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
											<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z' />
										</svg>
									</div>
									<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
										<h3 className='text-lg font-medium text-gray-900 leading-6' id='modal-headline'>
											Download Code
										</h3>
										<div className='mt-2'>
											<p className='text-sm text-gray-500'>
												Please input your download code. If you do not have one, please reach out to hello@blocktag.com for more details.
											</p>
											<input className='w-full p-4 mt-4 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 rounded-md' placeholder='Code' onChange={() => setError('')} />
											<p className='mt-2 text-sm text-red-600'>{error}</p>
										</div>
									</div>
								</div>
							</div>

							<div className='px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse'>
								<button type='button' className='inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-teal-600 border border-transparent rounded-md shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:ml-3 sm:w-auto sm:text-sm' onClick={() => setError('Invalid code')}>
									Download
								</button>
								<button type='button' className='inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm' onClick={() => { setLoginVisible(false); setError('') }}>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div> : null}

            {/* <!-- <SubBanner /> -->  */}
		</>
	)
}

// Email address and subscribe form
function SubBanner () {
	return (
		<div className='w-full py-4 bg-teal-600'>
			<div className='container mx-auto'>
				<div className='flex flex-wrap items-center text-sm text-teal-100'>
					<span className='max-w-lg px-4 text-xs'>Blocktag consists of security technologies to authenticate, identify, and prove presence of physical things. From origin to consumer and beyond. To learn more, <a href='https://www.blocktag.com' className='font-bold hover:underline'>click here</a>.
					</span>
					<div className='flex-grow' />
					<InquiryForm />
					{/*
					<div className='w-full max-w-sm'>
						<div className='relative text-gray-700'>
							<input className='w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded focus:shadow-outline' type='email' name='email' placeholder='Email Address' />
							<button className='absolute inset-y-0 right-0 flex items-center px-4 font-bold text-white bg-teal-500 rounded-r hover:bg-teal-400 focus:bg-teal-700'>Subscribe</button>
						</div>
					</div>
					*/}
				</div>
			</div>
		</div>
	)
}
