import React from 'react'
import { useQuery } from '@apollo/client'
import { NotificationsQuery } from '../../api'

export default function () {
	const { data } = useQuery(NotificationsQuery)

	let visible = false; let className = ''; let message = ''
	if (data) {
		if (data.error) {
			visible = true
			className = 'bg-red-500'
			message = data.error
		} else if (data.info) {
			visible = true
			className = 'bg-green-500'
			message = data.info
		}
	}

	return (
		<>
			{visible
				? <div className='py-4 text-center bg-indigo-900 lg:px-4'>
					<div className={`${className} flex items-center p-2 leading-none text-indigo-100 bg-indigo-800 lg:rounded-full lg:inline-flex`} role='alert'>
						<span className='flex px-2 py-1 mr-3 text-xs font-bold uppercase bg-red-500 rounded-full'>Error</span>
						<span className='flex-auto mr-2 font-semibold text-left'>{message}</span>
					</div>
				</div>
				: <></>}
		</>
	)
}

