import React from 'react'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { TagListQuery } from '../../api'
import CryptoSignEth from '../cryptosign/eth'

const Ellipses = (
	<svg width='14' height='4' viewBox='0 0 14 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g opacity='0.4'>
			<circle cx='2.19796' cy='1.80139' r='1.38611' fill='#222222' />
			<circle cx='11.9013' cy='1.80115' r='1.38611' fill='#222222' />
			<circle cx='7.04991' cy='1.80115' r='1.38611' fill='#222222' />
		</g>
	</svg>
)

const Magnifier = (
	<svg className='w-3 h-3 fill-current' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 511.999 511.999'>
		<path d='M508.874 478.708L360.142 329.976c28.21-34.827 45.191-79.103 45.191-127.309C405.333 90.917 314.416 0 202.666 0S0 90.917 0 202.667s90.917 202.667 202.667 202.667c48.206 0 92.482-16.982 127.309-45.191l148.732 148.732c4.167 4.165 10.919 4.165 15.086 0l15.081-15.082c4.165-4.166 4.165-10.92-.001-15.085zM202.667 362.667c-88.229 0-160-71.771-160-160s71.771-160 160-160 160 71.771 160 160-71.771 160-160 160z' />
	</svg>

)

const ArrowL = (
	<svg className='w-6 h-6' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g opacity='0.4'>
			<path fillRule='evenodd' clipRule='evenodd' d='M9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071L13.2929 16.7072C13.6834 17.0977 14.3166 17.0977 14.7071 16.7072C15.0977 16.3167 15.0977 15.6835 14.7071 15.293L11.4142 12L14.7071 8.70712C15.0977 8.31659 15.0977 7.68343 14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12Z' fill='#2C2C2C' />
		</g>
	</svg>
)

const ArrowR = (
	<svg className='w-6 h-6' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd' d='M15 12C15 11.7348 14.8946 11.4804 14.7071 11.2929L10.7071 7.2929C10.3166 6.9024 9.6834 6.9024 9.2929 7.2929C8.9024 7.6834 8.9024 8.3166 9.2929 8.7071L12.5858 12L9.2929 15.2929C8.9024 15.6834 8.9024 16.3166 9.2929 16.7071C9.6834 17.0976 10.3166 17.0976 10.7071 16.7071L14.7071 12.7071C14.8946 12.5196 15 12.2652 15 12Z' fill='#18A0FB' />
	</svg>
)

export default () => {
	const history = useHistory()

	const goTo = btid => {
		return () => {
			history.push(`/${btid}`)
		}
	}

	const { loading, error, data } = useQuery(TagListQuery)

	if (loading) return ''
	if (error) return `Error: ${error.message}`

	return (
		<div className='w-full px-4 pb-4 bg-white rounded-md'>
			<div className='flex justify-between w-full pt-6 '>
				<p className='ml-3'>Tags</p>
				{Ellipses}
			</div>
			<div className='flex justify-end w-full px-2 mt-2'>
				<div className='relative inline-block w-full sm:w-64 '>
					<input type='' name='' className='block w-full px-4 py-1 pl-8 text-sm leading-snug text-gray-600 bg-gray-100 border border-gray-300 rounded-lg appearance-none' placeholder='Search' />

					<div className='absolute inset-y-0 left-0 flex items-center px-2 pl-3 text-gray-300 pointer-events-none'>
						{Magnifier}
					</div>
				</div>
			</div>
			<div className='mt-6 overflow-x-auto'>

				<table className='w-full border-collapse table-fixed'>
					<thead>
						<tr className='text-sm font-medium text-left text-gray-700 rounded-lg' style={{ fontSize: '0.9674rem' }}>
							<th className='px-4 py-2 bg-gray-200' style={{ width: '5rem' }}>BTID</th>
							<th className='px-4 py-2 bg-gray-200' style={{ width: '5rem' }}>Created</th>
							<th className='px-4 py-2 bg-gray-200' style={{ width: '8rem' }}>Sig</th>
							<th className='px-4 py-2 bg-gray-200'>Payload</th>
						</tr>
					</thead>
					<tbody className='text-sm font-normal text-gray-700'>
						{data.tags.map(({ btid, createdAt, payload, hashfn, sig }) => (
							<tr key={btid} className='max-w-full py-10 align-top border-b border-gray-200 cursor-pointer group hover:bg-gray-100' onClick={goTo(btid)}>
								<td className='px-4 py-4' title={btid}>{btid.substring(0, 8)}</td>
								<td className='px-4 py-4'>{createdAt}</td>
								<td className='px-4 py-4 truncate' title={`${hashfn} : ${sig}`}>{(hashfn || '').substring(0, 1)}:{sig}</td>
								<td className='flex px-4 py-4'>
									<span className='flex-grow truncate'>{payload}</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div id='pagination' className='flex items-center justify-center w-full pt-4 border-t border-gray-100'>
				{ArrowL}
				<p className='mx-2 text-sm leading-relaxed text-blue-600 cursor-pointer hover:text-blue-600'>1</p>
				<p className='mx-2 text-sm leading-relaxed cursor-pointer hover:text-blue-600'>2</p>
				<p className='mx-2 text-sm leading-relaxed cursor-pointer hover:text-blue-600'> 3 </p>
				<p className='mx-2 text-sm leading-relaxed cursor-pointer hover:text-blue-600'> 4 </p>
				{ArrowR}
			</div>

			<div className='mt-6'>
				<CryptoSignEth />
			</div>
		</div>
	)
}
