import React from 'react'
import { Link } from 'react-router-dom'
import config from 'config'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons'

// import SubscribeForm from '../SubscribeForm'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'

const col1 = [
	['Home', '/'],
	['Products', '/#products'],
	['Blog', '/blog'],
	// ['About Us', '/about'],
	// ['Page 3', '/about'],
	// ['Contacts', '/contacts'],
]

const PreFooter = () => {
	return (
		<div className='flex flex-wrap my-16 overflow-hidden text-sm font-light text-gray-800'>
			<div className='w-full md:w-1/4'>
				<div className='font-medium'>Main Categories</div>
				{col1.map((row, i) => (
					<Link key={`prefooter_cat_${i}`} to={row[1]} className='block my-3'>
						{row[0]}
					</Link>
				))}
			</div>
			<div className='w-full md:w-1/2'>
				<div className='font-medium'>Contact</div>
				<div className='block my-3'>
					<a href={`mailto:${config.email}`}>{config.email}</a>
				</div>
				<div className='block my-3 whitespace-pre-line'>
					{config.address}
				</div>
				{config.phone
					? <div className='block my-3'>
						<a href={`tel:+${config.phone.replace(/\D/g, '')}`}>{config.phone}</a>
					</div>
					: <div />}
			</div>
		</div>
	)
}

export default PreFooter
