import { gql } from "@apollo/client";

export const TagListQuery = gql`
  {
    tags {
      btid
      createdAt
      payload
      hashfn
      sig
    }
  }
`;

export const TagDetailQuery = gql`
  query tag($btid: String!) {
    tag(btid: $btid) {
      btid
      createdAt
      payload
      sig
      bevents {
        id
        ts
        type
        from
        hashfn
        sig
        payload
      }
    }
  }
`;

export const AddBEvent = gql`
  mutation InsertBevent(
    $from: String!
    $btid: String
    $type: BeventType!
    $payload: String!
    $hashfn: Hashfn!
    $sig: String!
  ) {
    insertBevent(
      from: $from
      btid: $btid
      type: $type
      payload: $payload
      hashfn: $hashfn
      sig: $sig
    ) {
      id
      ts
      type
      from
      hashfn
      sig
      payload
    }
  }
`;
// Local state - uses @client directive

export const NotificationsQuery = gql`
  query GetNotifications @client {
    info
    error
  }
`;
// Define the updateBevent mutation
export const UpdateBEvent = gql`
  mutation UpdateBevent(
    $id: ID!
    $btid: String!
    $from: String!
    $type: BeventType!
    $payload: String!
    $hashfn: Hashfn!
    $sig: String!
  ) {
    updateBevent(
      id: $id
      from: $from
      type: $type
      payload: $payload
      hashfn: $hashfn
      sig: $sig
      btid: $btid
    ) {
      id
      ts
      type
      from
      hashfn
      sig
      payload
    }
  }
`;