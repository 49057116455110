import { Amplify, API } from "aws-amplify";
import awsconfig from "./aws-exports";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import TagList from "./components/tag/list";
import TagDetail from "./components/tag/detail";
import TagDetailLayout from "./components/tag/detail_layout";
import Layout from "./components/Layout";
import { checkIfCookieExists } from "./services/handleCookies";

function App() {
  Amplify.configure(awsconfig);
  console.log("AWS Config");
 
  useEffect(() => {
    checkIfCookieExists();
  }, []);
  return (
    <Router>
      <Layout>
        <div>
          <Switch>
            <Route path="/:id" children={<Child />} />
            <Route path="/" children={<TagList />} />
          </Switch>
        </div>
      </Layout>
    </Router>
  );
}

function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const { id } = useParams();

  return (
    <div>
      <TagDetailLayout btid={id} />
      <hr />
      <TagDetail btid={id} />
    </div>
  );
}

export default App;
