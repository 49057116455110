import React, { Component } from 'react'
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from 'ajv';

import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';


const ajv = new Ajv({ allErrors: true, verbose: true });

export default class JsonEditor extends Component {
  constructor(props) {
    super(props)
    // value, onChange

  }
  render() {

    return (
      <Editor
        statusBar={true}
        search={true}
        value={this.props.value}
        onChange={this.props.handleChange}
        mode="code"
        ace={ace}
        ajv={ajv}
        theme="ace/theme/github"
        htmlElementProps={{ style: { height: 600 } }}
        schema={schema}
      />
    );
  }
}

const schema = {
  //"$schema": "http://json-schema.org/draft-04/schema#",
  "type": "object",
  "properties": {
    "@context": {
      "type": "object",
      "properties": {
        "@vocab": {
          "type": "string"
        },
        "gr": {
          "type": "string"
        },
        "pto": {
          "type": "string"
        },
        "foaf": {
          "type": "string"
        },
        "xsd": {
          "type": "string"
        },
        "foaf:page": {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            }
          },
          "required": [
            "@type"
          ]
        }
      },
      "required": [
        "@vocab",
        "gr",
        "pto",
        "foaf",
        "xsd",
        "foaf:page"
      ]
    },
    "@id": {
      "type": "string"
    },
    "@type": {
      "type": "string"
    },
    "additionalType": {
      "type": "string"
    },
    "name": {
      "type": "string"
    },
    "serialNumber": {
      "type": "string"
    },
    "brand": {
      "type": "string"
    },
    "category": {
      "type": "string"
    },
    "gtin": {
      "type": "string"
    },
    "manufacturer": {
      "type": "string"
    },
    "model": {
      "type": "string"
    },
    "productID": {
      "type": "string"
    },
    "productionDate": {
      "type": "string"
    },
    "weight": {
      "type": "object",
      "properties": {
        "value": {
          "type": "string"
        },
        "unitCode": {
          "type": "string"
        }
      },
      "required": [
        "value",
        "unitCode"
      ]
    },
    "identifier": {
      "type": "string"
    },
    "image": {
      "type": "string"
    },
    "mainEntityOfPage": {
      "type": "string"
    },
    "url": {
      "type": "string"
    },
    "subjectOf": {
      "type": "array",
      "items": [
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "startDate": {
              "type": "string"
            },
            "endDate": {
              "type": "string"
            },
            "about": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "eventStatus": {
              "type": "string"
            },
            "image": {
              "type": "string"
            },
            "organizer": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "url"
              ]
            },
            "location": {
              "type": "object",
              "properties": {
                "@type": {
                  "type": "string"
                },
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                },
                "address": {
                  "type": "string"
                }
              },
              "required": [
                "@type",
                "name",
                "url",
                "address"
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "startDate",
            "endDate",
            "about",
            "description",
            "eventStatus",
            "image",
            "organizer",
            "location"
          ]
        },
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "startDate": {
              "type": "string"
            },
            "endDate": {
              "type": "string"
            },
            "about": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "eventStatus": {
              "type": "string"
            },
            "image": {
              "type": "string"
            },
            "organizer": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "url"
              ]
            },
            "location": {
              "type": "object",
              "properties": {
                "@type": {
                  "type": "string"
                },
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                },
                "address": {
                  "type": "string"
                }
              },
              "required": [
                "@type",
                "name",
                "url",
                "address"
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "startDate",
            "endDate",
            "about",
            "description",
            "eventStatus",
            "image",
            "organizer",
            "location"
          ]
        },
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "startDate": {
              "type": "string"
            },
            "endDate": {
              "type": "string"
            },
            "about": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "eventStatus": {
              "type": "string"
            },
            "image": {
              "type": "string"
            },
            "organizer": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "url"
              ]
            },
            "location": {
              "type": "object",
              "properties": {
                "@type": {
                  "type": "string"
                },
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                },
                "address": {
                  "type": "string"
                }
              },
              "required": [
                "@type",
                "name",
                "url",
                "address"
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "startDate",
            "endDate",
            "about",
            "description",
            "eventStatus",
            "image",
            "organizer",
            "location"
          ]
        },
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "startDate": {
              "type": "string"
            },
            "endDate": {
              "type": "string"
            },
            "about": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "eventStatus": {
              "type": "string"
            },
            "image": {
              "type": "string"
            },
            "organizer": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "url"
              ]
            },
            "location": {
              "type": "object",
              "properties": {
                "@type": {
                  "type": "string"
                },
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                },
                "address": {
                  "type": "string"
                }
              },
              "required": [
                "@type",
                "name",
                "url",
                "address"
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "startDate",
            "endDate",
            "about",
            "description",
            "eventStatus",
            "image",
            "organizer",
            "location"
          ]
        },
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "startDate": {
              "type": "string"
            },
            "endDate": {
              "type": "string"
            },
            "about": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "eventStatus": {
              "type": "string"
            },
            "image": {
              "type": "string"
            },
            "organizer": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "url"
              ]
            },
            "location": {
              "type": "object",
              "properties": {
                "@type": {
                  "type": "string"
                },
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                },
                "address": {
                  "type": "string"
                }
              },
              "required": [
                "@type",
                "name",
                "url",
                "address"
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "startDate",
            "endDate",
            "about",
            "description",
            "eventStatus",
            "image",
            "organizer",
            "location"
          ]
        },
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "startDate": {
              "type": "string"
            },
            "endDate": {
              "type": "string"
            },
            "about": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "eventStatus": {
              "type": "string"
            },
            "image": {
              "type": "string"
            },
            "organizer": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "url"
              ]
            },
            "location": {
              "type": "object",
              "properties": {
                "@type": {
                  "type": "string"
                },
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                },
                "address": {
                  "type": "string"
                }
              },
              "required": [
                "@type",
                "name",
                "url",
                "address"
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "startDate",
            "endDate",
            "about",
            "description",
            "eventStatus",
            "image",
            "organizer",
            "location"
          ]
        },
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "startDate": {
              "type": "string"
            },
            "endDate": {
              "type": "string"
            },
            "about": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "eventStatus": {
              "type": "string"
            },
            "image": {
              "type": "string"
            },
            "organizer": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "url"
              ]
            },
            "location": {
              "type": "object",
              "properties": {
                "@type": {
                  "type": "string"
                },
                "name": {
                  "type": "string"
                },
                "url": {
                  "type": "string"
                },
                "address": {
                  "type": "string"
                }
              },
              "required": [
                "@type",
                "name",
                "url",
                "address"
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "startDate",
            "endDate",
            "about",
            "description",
            "eventStatus",
            "image",
            "organizer",
            "location"
          ]
        }
      ]
    },
    "additionalProperty": {
      "type": "array",
      "items": [
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "value": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                },
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                }
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "value"
          ]
        },
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "value": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                },
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                },
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                },
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                }
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "value"
          ]
        },
        {
          "type": "object",
          "properties": {
            "@type": {
              "type": "string"
            },
            "name": {
              "type": "string"
            },
            "value": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                },
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                },
                {
                  "type": "object",
                  "properties": {
                    "@type": {
                      "type": "string"
                    },
                    "name": {
                      "type": "string"
                    },
                    "value": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "@type",
                    "name",
                    "value"
                  ]
                }
              ]
            }
          },
          "required": [
            "@type",
            "name",
            "value"
          ]
        }
      ]
    }
  },
  "required": [
    "@context",
    "@id",
    "@type",
    "additionalType",
    "name",
    "serialNumber",
    "brand",
    "category",
    "gtin",
    "manufacturer",
    "model",
    "productID",
    "productionDate",
    "weight",
    "identifier",
    "image",
    "mainEntityOfPage",
    "url",
    "subjectOf",
    "additionalProperty"
  ]
}
