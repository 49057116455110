import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider, from } from '@apollo/client'
import { onError } from '@apollo/link-error'

import App from './App'
import InfoHeader from './components/InfoHeader'
import * as serviceWorker from './serviceWorker'
import { NotificationsQuery } from './api'

import './assets/css/styles.css'

const errorLink = onError(data => {
	const { graphQLErrors, networkError, operation } = data
	if (graphQLErrors) {
		console.log(graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			),
		)
	}

	if (networkError) console.log(`[Network error]: ${networkError}`)

	const { cache } = operation.getContext()
	cache.writeQuery({
		query: NotificationsQuery,
		data: { error: networkError ? networkError.message : null, info: null },
	})
})

const client = new ApolloClient({
	cache: new InMemoryCache({
		dataIdFromObject: obj => `${obj.__typename}:${obj.id || obj.btid}`,
	}),
	link: from([
		errorLink,
		new HttpLink({
			uri: process.env.REACT_APP_API_URL,
		}),
	]),
})

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<InfoHeader />
			<App />
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

