import React, { useState, Suspense, useRef } from 'react'
import { useQuery } from '@apollo/client'
import path from '@bit/ramda.ramda.path'
import dayjs from 'dayjs'
import titleize from 'titleize'

// import Map from '../map'
import Vimeo from '@u-wave/react-vimeo'
import { TagDetailQuery } from '../../api'

import { Canvas, useFrame, useLoader } from '@react-three/fiber' // Still need Three library in npm_mopdules
import { Environment, OrbitControls, Html, useProgress, useGLTF, Stage } from "@react-three/drei"; // ,draco
import * as THREE from "three"
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
//import { DRACOLoader }  from 'three/examples/jsm/loaders/DRACOLoader.js'

// http://localhost:3000/10399cc7-0839-4f17-9122-35974b5179d2 sample data
// TODO: all subjectOf events need to be concatenated.

export default ({ btid }) => {
	const { loading, error, data } = useQuery(TagDetailQuery, {
		variables: { btid },
	})

	// TODO: make a timeout for loading
	if (loading) return ''
	if (error) return `Error: ${error}`

	const { bevents } = data.tag

	// find data within payload, using either [path0, path1] or ['additionalProperty', '<theproperty>']
	function findData (q) {
		const query = Array.isArray(q) ? q : [q]

		for (let i = bevents.length - 1; i >= 0; i--) {
			const { payload } = bevents[i]
			const pl = JSON.parse(payload)

			if (query[0] === 'additionalProperty' && pl.additionalProperty) {
				if (query.length < 2) throw new Error('asdf')
				const result = pl.additionalProperty.find(({ name }) => name === query[1])
				if (result) return result
			}

			// use query as path
			const result = path(query, pl)
			if (result) return result
		}
	}

	function findAndPrint (p, title) {
		const result = findData([p])
		if (result) {
			return (
				<h3 className='pb-4'>
					<span className='font-medium' style={color967}>{title || titleize(p[0])}</span>
					: {result}
				</h3>
			)
		}
	}
    
    
    // "http://1.165.154.116/violin/index.html"
    if (window.location.href === "https://app.blocktag.com/CAEaKiiJhtQQNYNAg004goKQEEoFQIGEmEBKBUCBhNBASgVAgYSAcFCBgNCdBQ==") {
        window.location.replace("https://app.blocktag.com/CNPFjZEG"); // JAB tag design on violin
        return
    }
    
    if (window.location.href === "https://app.blocktag.com/CK+5g5AG") { // QR tag design on another violin
        window.location.replace("https://app.blocktag.com/CNPFjZEG");
        return
    }

    if (window.location.href === "https://app.blocktag.com/CPrv+5EG") {
        window.location.replace("https://app.blocktag.com/CNPFjZEG");
        return
    }
    
    if (window.location.href === "https://app.blocktag.com/CKHKq5EG") { // QR tag design on violin certificate
        window.location.replace("https://app.blocktag.com/CNPFjZEG");
        return
    }
    
    // Higher greeting cards redirect
    if (window.location.href === "https://app.blocktag.com/CKe+7ZwG") {window.location.replace("http://highergreetings.com/");return;}
    if (window.location.href === "https://app.blocktag.com/CKq+7ZwG") {window.location.replace("http://highergreetings.com/");return;}
    if (window.location.href === "https://app.blocktag.com/CKu+7ZwG") {window.location.replace("http://highergreetings.com/");return;}
    if (window.location.href === "https://app.blocktag.com/CK2+7ZwG") {window.location.replace("http://highergreetings.com/");return;}
    if (window.location.href === "https://app.blocktag.com/CK++7ZwG") {window.location.replace("http://highergreetings.com/");return;}
    
    
    if (window.location.href === "http://app.blocktag.com/CLHf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLXf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLnf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLvf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CL7f9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMHf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMTf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMff9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMrf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMzf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CM-f9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNLf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNXf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNjf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNvf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CN3f9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CODf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COPf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COff9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COrf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CO3f9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPHf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPTf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPjf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPvf9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CP7f9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIHg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CITg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIfg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIrg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CI3g9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJHg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJTg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJfg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJvg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJ7g9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKHg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKTg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKfg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKrg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CK3g9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLDg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLTg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLfg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLrg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CL3g9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMDg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMTg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMfg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMrg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CM3g9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNHg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNTg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNfg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNvg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CN7g9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COHg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COTg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COjg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COvg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CO7g9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPHg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPXg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPjg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPvg9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CP7g9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIHh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIXh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIjh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIvh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CI-h9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJLh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJXh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJnh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJzh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJ-h9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKLh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKbh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKrh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CK3h9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLDh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLPh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLfh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLrh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CL7h9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMHh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMTh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMjh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CMvh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CM7h9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNLh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNXh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CNnh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CN3h9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CODh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COPh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CObh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COnh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/COzh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPDh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPPh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPbh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CPnh9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CP7h9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CILi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIXi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIji9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CIvi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CI7i9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJHi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJTi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJfi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJri9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CJ7i9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKPi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKbi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CKri9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CK7i9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLHi9pwG") {window.location.replace("http://highergreetings.com");return}
    if (window.location.href === "http://app.blocktag.com/CLXi9pwG") {window.location.replace("http://highergreetings.com");return}

    
    
    if (window.location.href === "https://app.blocktag.com/CLrQ3Z8G") {window.location.replace("https://www.savvycard.com/");return}
    if (window.location.href === "https://app.blocktag.com/CL7Q3Z8G") {window.location.replace("https://www.savvycard.com/");return}
    if (window.location.href === "https://app.blocktag.com/CL-Q3Z8G") {window.location.replace("https://www.savvycard.com/");return}
    if (window.location.href === "https://app.blocktag.com/CMHQ3Z8G") {window.location.replace("https://www.savvycard.com/");return}
    if (window.location.href === "https://app.blocktag.com/CMPQ3Z8G") {window.location.replace("https://www.savvycard.com/");return}
    
    if (window.location.href === "https://app.blocktag.com/CMGu65wG") {window.location.replace("https://www.welllink.com.tw/en/home/");return}
    if (window.location.href === "https://app.blocktag.com/CL+u65wG") {window.location.replace("https://www.welllink.com.tw/en/home/");return}
    if (window.location.href === "https://app.blocktag.com/CL6u65wG") {window.location.replace("https://www.welllink.com.tw/en/home/");return}
    if (window.location.href === "https://app.blocktag.com/CLyu65wG") {window.location.replace("https://www.welllink.com.tw/en/home/");return}

    
    

    
    
    const getMobileOS = () => {
      const ua = navigator.userAgent
      if (/android/i.test(ua)) {
        return "Android"
      }
      else if ( (/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ){
        return "iOS"
      }
      return "Other"
    }
    

    // https://stackoverflow.com/questions/68201867/js-navigator-mediadevices-getusermedia-not-working
    // https://stackoverflow.com/questions/52812091/getusermedia-selecting-rear-camera-on-mobile
    // https://developer.apple.com/forums/thread/92713 Get user media work on iOS
    async function startCapture(displayMediaOptions) {
        let stream = null;

        try {
            
            // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getSupportedConstraints
            let supportedConstraints = await navigator.mediaDevices.getSupportedConstraints();
            let constraintList = document.getElementById("constraintList");
            for (const constraint of Object.keys(supportedConstraints)) {
                let elem = document.createElement("li");
                elem.innerHTML = constraint;
                constraintList.appendChild(elem);
            }

            
            stream = await navigator.mediaDevices.getUserMedia(displayMediaOptions);
            let videoContainer = document.getElementById("videoContainer");
            let video = document.createElement("video");
            video.srcObject = stream;
            video.setAttribute('id', "videoAuth");
            video.setAttribute('autoPlay', true);
            video.setAttribute('playsInline', true);
            video.setAttribute('muted', true);
            videoContainer.appendChild(video);
            
            
            let buttonClickPhoto = document.createElement("button");
            buttonClickPhoto.setAttribute('id', "click-photo");
            buttonClickPhoto.innerHTML = "CapturePhoto";
            videoContainer.appendChild(buttonClickPhoto);


//            let click_button = document.querySelector("#click-photo");
//            let canvas = document.querySelector("#canvas");
            buttonClickPhoto.addEventListener('click', function() {
                
                let videoAuthElem = document.getElementById("videoAuth");
                console.log("videoAuthElem ", videoAuthElem)
                
                let canvasPhoto = document.createElement("canvas");
                canvasPhoto.setAttribute('id', "canvasPhoto");
                canvasPhoto.setAttribute('width', videoAuthElem.videoWidth);
                canvasPhoto.setAttribute('height', videoAuthElem.videoHeight);
                console.log("videoWidth", video.videoWidth)
                console.log("videoHeight", video.videoHeight)

                videoContainer.appendChild(canvasPhoto);


                canvasPhoto.getContext('2d').drawImage(videoAuthElem, 0, 0, videoAuthElem.videoWidth, videoAuthElem.videoHeight);
                let image_data_url = canvasPhoto.toDataURL('image/jpeg');

                   // data url of the image
                   console.log(image_data_url);
            });
            
            
        } catch(err) {
            console.error(err);
            alert('Error', err);

        }
    }
    

    // Only trigger webcam for non iOS devices
    const os = getMobileOS()
//    if (!os.includes("iOS")) {
        // https://whatwebcando.today/articles/choose-front-back-camera-stream/
        startCapture({
        audio: false,
        video: {
            facingMode: 'environment',
            width: {
                min: window.screen.width
            }
        }
        });
//    }
    


    
    // Choose product page template
    if (findData('category').includes("Instrument")) {
                
        return (
                <div className='container mx-auto'>
                    <div className='flex flex-wrap p-4 bg-white sm:pt-24 rounded-md'>
                
                
                        {/* 3D Model Viewer */}
                        {/*<h1 className='px-4 mt-8 mb-4 text-3xl font-hairline'>3D Physical NFT</h1>*/}
                        {window.location.href === "https://app.blocktag.com/CNPFjZEG"
                        ? <div id="canvas-container" className='w-full' style={{ height: '70vh' }}>
                                <Scene />
                                {/* {findAndPrint(['category'])} */}
                            
                            </div>
                        : findData(['profilePics'])
                            ? <div className=''>
                                <div className='flex flex-wrap text-sm'>
                                    {findData(['profilePics']).map(({ image }, i) => (
                                        <div key={`history_${i}`} className='w-full pb-8 md:px-4 md:w-1/3'>
                                        <img className='mx-auto' src={'data:image/jpeg;base64,' + image} alt='Item' />
                                        </div>
                                    )) }
                                </div>
                              </div>
                            : <div />
                        }
                        
                        <HideControl btid={btid} findData={findData} />

                    
                    </div>
  
                </div>
                
        )
    }
    else {

        return (

            <div className='container mx-auto'>
                <div className='flex flex-wrap p-4 bg-white sm:pt-24 rounded-md'>
                    {/* Title Image */}
                    {/*<div className='w-full pb-8 md:px-4 md:w-1/2'>*/}
                    {/*<img className='mx-auto' style={{ width: '300px' }} src={'data:image/jpeg;base64,' + findData('image')} alt='Item' />*/}
                    {/*</div>*/}
                
                
                {/* 3D Model Viewer */}
                {/*<h1 className='px-4 mt-8 mb-4 text-3xl font-hairline'>3D Physical NFT</h1>*/}
                
                
                {window.location.href === "https://app.blocktag.com/CNPFjZEG"
                ? <div id="canvas-container" className='w-full' style={{ height: '70vh' }}><Scene /></div>
                : findData(['profilePics'])
                    ? <div className=''>
                        <div className='flex flex-wrap text-sm'>
                            {findData(['profilePics']).map(({ image }, i) => (
                                <div key={`history_${i}`} className='w-full pb-8 md:px-4 md:w-1/3'>
                                <img className='mx-auto' src={'data:image/jpeg;base64,' + image} alt='Item' />
                                </div>
                            )) }
                        </div>
                      </div>
                    : <div />
                }

                

                
                {/* {findData(['profilePics'])
                    ? <div className=''>
                        <div className='flex flex-wrap text-sm'>
                            {findData(['profilePics']).map(({ image }, i) => (
                                <div key={`history_${i}`} className='w-full pb-8 md:px-4 md:w-1/3'>
                                <img className='mx-auto' src={'data:image/jpeg;base64,' + image} alt='Item' />
                                </div>
                            )) }
                        </div>
                    </div>
                    : <div />}
                  */}
                


                
                {/* Tag Fingerprint Viewer */}
                <h1 className='px-4 mt-8 mb-4 text-3xl font-hairline'>Authentication Tag </h1>
                
                
                <div className='px-4'>
                    <p className='font-bold'>Please authenticate fingerprinted tag on item with Blocktag app to view authentication protected data.</p>

                    <div className='w-full text-center '>
                        <form className='font-semibold tracking-wider text-white uppercase text-md'
                            action="https://apps.apple.com/us/app/blocktag/id1478833776">
                            <input type="image" src="/demo/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" />
                        </form>
                
                        <div id="videoContainer">
                            {/*<video autoplay="true" id="videoElement" style={{ width: '500px', height: '375px' }}></video> */}
                        </div>
                
                


                    </div>
                
                    <div id="constraintList" className="text-left"> Supported Camera Parameters</div>

                </div>
                
                {/* Temp placeholder for fingerprinted tag */}
                {/*<div className='w-full pb-8 md:px-4'><img className='mx-auto' src='/demo/tag.jpeg' alt='Fingerprinted Tag' /></div>*/}



                    {/* Certifications */}
                    { /* findData(['additionalProperty', 'CA Compliance'])
                        ? <div className='w-full px-4 mb-12 text-gray-700 md:w-1/2'>
                            <h1 className='mb-4 text-3xl font-hairline'>Certifications</h1>

                            <div className='mb-4 text-sm'>
                                <p className='pb-4'>Green Stuff Labs (ISO/IEC 17025 accredited)</p>
                                <AdditionalProperty title='CA Compliance' property='CA Compliance' findData={findData} />
                                <AdditionalProperty title='Compliance' property='Compliance' findData={findData} />
                                <AdditionalProperty title='Legal for Import' property='Legal for Import' findData={findData} />
                            </div>
                        </div> : null */ }



                    {/* Announcements */}
                    {/*
                    <div className='w-full px-4 mb-12 text-gray-700 md:w-1/2'>
                        <h1 className='mb-4 text-3xl font-hairline'>Announcements</h1>

                        <div className='mb-4 text-sm'>
                            
                            <a href='https://www.cambridgeindependent.co.uk/business/ppe-fraud-it-s-coming-out-of-mainly-china-and-taiwan-says-cyber-security-expert-9108529/'>
                                <div className='flex w-full max-w-full mb-4'>
                                    <div className='flex-none w-32 h-auto overflow-hidden text-center bg-cover rounded-t-none rounded-t rounded-l' style={{ backgroundImage: `url('/demo/news1.jpg')` }} title='Woman holding a mug' />
                                    <div className='flex flex-col justify-between flex-grow p-4 leading-normal bg-red-100 rounded-b rounded-r'>
                                        <div className='mb-2'>
                                            <p className='flex items-center text-xs text-gray-600'>
                                                05/2020
                                            </p>
                                            <p className='w-full text-lg italic font-medium text-gray-900'>
                                                Product Safety Recall
                                            </p>
                                            <p className='w-full text-gray-700 font-base'>
                                                The mask you scanned (<a className='text-blue-400' href='#d4Wzkzj4DO90BVkdwkxp14MNu'>#d4Wzkz</a>) came from a container shipment that has suspected quality issues. For your safety please don't use the mask, or go to the nearest pharmacy for exchange.
                                            </p>
                                        </div>
                                        <div className='flex items-center'>
                                            <img className='w-10 h-10 mr-4 rounded-full' src='/img/jonathan.jpg' alt='Avatar of Jonathan Reinink' />
                                            <div className='text-sm'>
                                                <p className='leading-none text-gray-900'>by <a href='https://cambridgeindependent.co.uk'>Cambridge Independent</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>

                              
                            <a href='https://www.cambridgeindependent.co.uk/business/ppe-fraud-it-s-coming-out-of-mainly-china-and-taiwan-says-cyber-security-expert-9108529/'>
                                <div className='flex w-full max-w-full mb-4'>
                                    <div className='flex-none w-32 h-auto overflow-hidden text-center bg-cover rounded-t-none rounded-t rounded-l' style={{ backgroundImage: `url('/demo/news2.jpg')` }} title='Woman holding a mug' />
                                    <div className='flex flex-col justify-between flex-grow p-4 leading-normal bg-gray-200 rounded-b rounded-r'>
                                        <div className='mb-2'>
                                            <p className='flex items-center text-xs text-gray-600'>
                                                05/2020
                                            </p>
                                            <p className='w-full text-lg italic font-medium text-gray-900'>
                                                Counterfeit Made In Taiwan Mask Spotted
                                            </p>
                                            <p className='w-full text-gray-700 font-base'>
                                                Buyer beware: Click on article to educate yourself on how to avoid wearing counterfeit masks.
                                            </p>
                                        </div>
                                        <div className='flex items-center'>
                                            <img className='w-10 h-10 mr-4 rounded-full' src='/img/jonathan.jpg' alt='Avatar of Jonathan Reinink' />
                                            <div className='text-sm'>
                                                <p className='leading-none text-gray-900'>by <a href='https://taiwannews.com.tw'>Taiwan News</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            
                              

                        </div>
                    </div>
                    */}
                                                                      

                    {/* <div key={`history_${i}`} className='mb-4'>
                                        <span className='font-medium' style={color967}>{dayjs(startDate).format('MM/YYYY')} </span>
                                        {description} by <a href={organizer.url}>{organizer.name}</a>
                                    </div> */}

                </div>
                
                
                
                
                
                {/* Title and metadata  */}
                <div className='w-full px-4 text-gray-700'>
                    <h4 className='text-xs font-light text-gray-500'><span className='font-semibold'>ID:&nbsp;</span>{btid}</h4>
                    <h1 className='text-4xl font-hairline'>{findData('name')}</h1>
                    <h1 className='text-4xl font-hairline'>{findData('name2')}</h1>
                    <h1 className='text-4xl font-hairline'>{findData('name3')}</h1>

                    {/* categories and specs */}
                    <div className='mb-4 text-sm'>
                    {/*{findAndPrint(['category'])}*/}
                    {/*{findAndPrint(['brand'])}*/}

                        {/* <h3><strong>Country of Origin</strong>: The Netherlands</h3>
                        <br />
                        <h3><strong>Type</strong>: Hybrid</h3>
                        <br /> */}
            
                        {/*
                        <h3 className='pb-4'>
                            <span className='font-medium' style={color967}>Weight</span>
                            : {findData(['weight', 'value'])} {findData(['weight', 'unitCode'])}
                        </h3> */}

                        {/* Was: Cannabinoids */}
                        {/*<AdditionalProperty title='Cannabinoids' property='Cannabinoids' findData={findData} />*/}

                        {/*
                        <h3 className='whitespace-pre-line'>
                            {`PharmLabs Coachella Valley LLC
                            Batch No.: 180720-007(2403)
                            Super Haze
                            THC | 16.00% | 160.0 mg/g
                            CBD | 00.13% |      1.3 mg/g
                            Mfg by Great Green LLC`}
                        </h3>
                        */}

                    </div>
                </div>
                
                
                {/* Was: Genetics */}
                <AdditionalPropertySection title='' property='Introduction' findData={findData} />

                <AdditionalPropertySection title='Structure' property='Structure' findData={findData} />
                


                {/* Map */}
                {/* <Map className='w-full py-12' style={{ height: '500px' }} /> */}

                {/* Event List */}
                {/* TODO: check if it has length > 0 */}
                {/* w-full px-4 mb-12 text-gray-700 md:w-1/2 */}
                {/* w-full max-w-full mb-4*/}
                {/* From 3 lines below: <h1 className='mb-4 text-3xl font-hairline'>Certificate</h1> */}
                {findData(['subjectOf'])
                    ? <div className='px-4'>
                        <h1 className='mb-4 text-3xl font-hairline'></h1>

                        {/* mb-4 text-sm */}
                        <div className='flex flex-wrap text-sm'>
                            {findData(['subjectOf']).map(({ startDate, organizer, name, description, location, image, type }, i) => (
                                                                                                                                     
                                <div key={`history_${i}`} className='w-full pb-8 md:px-4'>  {/*md:w-1/2*/}
                                    {/*style={{ width: '300px' }}*/}
                                    <img className='mx-auto' src={'data:image/jpeg;base64,' + image} alt='Item' />
                                                                                                                               
                                {/* }<div className='flex-none w-32 h-auto overflow-hidden text-center bg-cover rounded-t-none rounded-t rounded-l' style={{ backgroundImage: `url('/demo/face${i + 1}.jpg')` }} title='Event List' /> */}
                                                                                                                                     
                                    {/*bg-gray-200*/}
                                    <div className='flex flex-col justify-between flex-grow p-4 leading-normal rounded-b rounded-r'>
                                        <div className='mb-8'>
                                          {/*<p className='flex items-center text-xs text-gray-600'>{dayjs(startDate).format('MM/YYYY')} </p>*/}
                                            <p className='w-full text-lg font-medium text-gray-900'>{name}</p>
                                            <p className='w-full text-gray-700 font-base'>{description}</p>
                                        </div>
                                        <div className='flex items-center'>
                                      {/*}<img className='w-10 h-10 mr-4 rounded-full' src='/img/jonathan.jpg' alt='Avatar of Jonathan Reinink' />*/}
                                            <div className='text-sm'>
                                              {/*<p className='leading-none text-gray-900'>Captions <a href={organizer.url}>{organizer.name}</a>, {location.address}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )) }
                        </div>
                    </div>
                    : <div />}
                


                <AdditionalPropertySection title='' property='Certificate' findData={findData} />

                
                
                {findData(['subjectOf2'])
                    ? <div className='px-4'>
                        {/*<h1 className='mb-4 text-3xl font-hairline'>Historical Record</h1> */}
                        {/*<Vimeo
                            className='w-full pb-12'
                            video='424228594'
                            autoplay
                            controls={false}
                            loop
                            showTitle={false}
                            responsive
                            background
                        />*/}

                        {/* mb-4 text-sm */}
                        <div className='flex flex-wrap text-sm'>
                            {findData(['subjectOf2']).map(({ startDate, organizer, name, description, location, image, type }, i) => (
                                                                                                                               
                                <div key={`history_${i}`} className='w-full pb-8 md:px-4'>
                                <img className='mx-auto' src={'data:image/jpeg;base64,' + image} alt='Item' />
                                                                                                                               
                                {/* }<div className='flex-none w-32 h-auto overflow-hidden text-center bg-cover rounded-t-none rounded-t rounded-l' style={{ backgroundImage: `url('/demo/face${i + 1}.jpg')` }} title='Event List' /> */}
                                    <div className='flex flex-col justify-between flex-grow p-4 leading-normal bg-gray-200 rounded-b rounded-r'>
                                        <div className='mb-8'>
                                          {/*<p className='flex items-center text-xs text-gray-600'>{dayjs(startDate).format('MM/YYYY')} </p>*/}
                                            <p className='w-full text-lg font-medium text-gray-900'>{name}</p>
                                            <p className='w-full text-gray-700 font-base'>{description}</p>
                                        </div>
                                        <div className='flex items-center'>
                                      {/*}<img className='w-10 h-10 mr-4 rounded-full' src='/img/jonathan.jpg' alt='Avatar of Jonathan Reinink' />*/}
                                            <div className='text-sm'>
                                              {/*<p className='leading-none text-gray-900'>Captions <a href={organizer.url}>{organizer.name}</a>, {location.address}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )) }
                        </div>
                    </div>
                    : <div />}

                {/* Without muted parameter, video cannot autoplay */}
                {/* https://stackoverflow.com/questions/17994666/video-auto-play-is-not-working-in-safari-and-chrome-desktop-browser */}


                
                
                {window.location.href === "https://app.blocktag.com/CNPFjZEG"
                ?   <div>
                        <h1 className='px-4 mt-8 mb-4 text-3xl font-hairline'>Instrument Sound</h1>
                        <Vimeo
                            id='vid'
                            className='w-full pb-12'
                            video='674119192'
                            autoplay
                            controls={false}
                            loop
                            muted
                            showTitle={false}
                            responsive
                            background
                        />
                        <script> document.getElementById('vid').play(); </script>
                    </div>
                :   <div />
                }

            </div>
        )
    }
}


function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress.toFixed(0)} % loaded please wait...</Html>
}

// https://stackoverflow.com/questions/33696382/three-js-project-crashes-mobile
function ModelSmall() {
    
    const ref = useRef()
//    useFrame((state, delta) => (
//                                ref.current.rotation.x = 0,
//                                ref.current.rotation.y += 0.01,
//                                ref.current.rotation.z = 0

//                                ))
    const gltf = useGLTF('/demo/5.glb')
    
    return (
        <mesh ref={ref}>
          <primitive object={gltf.scene} />
        </mesh>
            )
}


// Doesnt work, but good reference: https://stackoverflow.com/questions/33959167/play-animation-once-instead-of-looping-three-js
// Works: https://stackoverflow.com/questions/63936267/how-to-extract-and-play-animation-in-react-three-fiber
function ModelBig() {

//    const ref = useRef()
//    useFrame((state, delta) => (
//                                ref.current.rotation.x = 0,
//                                ref.current.rotation.y += 0.01,
//                                ref.current.rotation.z = 0
//                                ))
    const gltf = useGLTF('/demo/5.glb')
    
    // Use custom animation
    let mixer
    if (gltf.animations.length) { // No. of keyframes e.g. =4
        mixer = new THREE.AnimationMixer(gltf.scene);
        gltf.animations.forEach(clip => {
            console.log("Animation Instance")
            const action = mixer.clipAction(clip)
            action.play();
        });
    }

    let duration
    useFrame((state, delta) => (
        duration = state.clock.getElapsedTime()
//        console.log("Length", gltf.animations.length),
//        duration<100 ? console.log("YES", duration) :  console.log("NO"),
//        duration<10 ? mixer?.update(delta) : mixer.stopAllAction() // timeScale = 0
    ))

    gltf.scene.traverse(child => {
        if (child.isMesh) {
            child.castShadow = false
            child.receiveShadow = false
            child.material.side = THREE.FrontSide
        }
    })
    
    
    return <primitive object={gltf.scene} /> // ref={ref} scale={0.4} position={[0, -100, 0]}

    //    const gltf = useLoader(GLTFLoader, '/demo/Violin0216_OBJ2.glb', draco)

    
//    const gltf = useLoader(GLTFLoader, '/demo/violin.glb', loader => {
//      const dracoLoader = new DRACOLoader();
//        dracoLoader.setDecoderPath( 'three/examples/js/libs/draco/' );
//        dracoLoader.setDecoderConfig({type: 'js'});
//        loader.setDRACOLoader(dracoLoader);
//     })
    
//    useLoader(GLTFLoader, url, (loader) => {
//      const dracoLoader = new DRACOLoader();
//      dracoLoader.setDecoderPath("/draco-gltf/");
//      loader.setDRACOLoader(dracoLoader);
//    });

    //    const draco = new DRACOLoader();
    //    draco.setDecoderConfig({ type: 'js' });
    //    draco.setDecoderPath( 'three/examples/js/libs/draco/' );
    
    
}

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

// https://stackoverflow.com/questions/69914906/how-to-move-the-camera-using-react-three-fiber-and-react-three-drei
function Scene() {
    
//    const draco = new DRACOLoader();
//    draco.setDecoderConfig({ type: 'js' });
//    draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

//    const gltf = useLoader(GLTFLoader, '/demo/violin.glb')
//    gltf.setDRACOLoader(draco);
//    console.log(gltf)
    
//    const gltf = useLoader(GLTFLoader, duck)
//    return <primitive object={gltf.scene} position={[0, 0, 0]} />

    


       
       // React Hook must be created before potential conditional return blocks
    //                                <Stage preset="portrait" intensity={0} shadows="false" contactShadow="false">
    //                                      <Environment preset="sunset" background />
    //                                </Stage>

       if (detectMob()) {
          return (
                  <>
                        <Canvas camera={{ fov: 35, zoom: 0.8, near: 1, far: 10000 }}>
                              <Suspense fallback={<Loader />}>
                  <Stage preset="portrait" intensity={0} shadows={0} contactShadow={0}>
                                      {<ModelSmall />}
                                      <OrbitControls makeDefault />
                                  </Stage>
                              </Suspense>
                          </Canvas>
                  </>
          )
      }
       else {
          return (
                  <>
                        <Canvas camera={{ fov: 35, zoom: 0.8, near: 1, far: 10000 }}>
                              <Suspense fallback={<Loader />}>
                  <Stage preset="portrait" intensity={0} shadows={0} contactShadow={0}>
                                  {<ModelBig />}
                                  <OrbitControls makeDefault/>
                                </Stage>
                              </Suspense>
                          </Canvas>
                  </>
          )
      }
    

    
//    const gltf = new GLTFLoader();
//    gltf.setDRACOLoader(draco);
//    const glbPath = '/demo/Violin0216_OBJ2.glb'
//    gltf.load(glbPath, function(gltf) {
//      console.log(gltf)
//
//        return (
//            <>
//                {<div className="App">
//                      <Canvas>
//                        <Suspense fallback={null}>
//                          <primitive object={gltf.scene} />
//                        </Suspense>
//                    </Canvas>
//                </div>}
//            </>
//        )
//    })
        

}

function AdditionalPropertySection ({ title, property, findData }) {
	const result = findData(['additionalProperty', property])
	if (!result) return null
	return (
		<>
			{result.value
				? <div className='w-full px-4 mb-12 text-gray-700 md:w-full'>
					<h1 className='mb-4 text-3xl font-hairline'>{title}</h1>
					<div className='mb-4 text-sm'>
						{result.value.map(({ name, value }, i) => (
                                                                   
                            <div key={`${property}_${i}`} className='mb-4'>{name ? <span className='font-medium' style={color967}>{name}: </span> : null}{value}</div>

//							<div key={`${property}_${i}`} className='mb-4'>{name ? `${name}: ${value}` : value}</div>
						))}
					</div>
				</div>
				: <div />}
			{result.image
				? <div className='w-full h-64 px-8 mb-12 bg-center bg-auto md:w-full' style={{ backgroundImage: `url(${result.image})` }} />
				: null}
		</>
	)
}

function AdditionalProperty ({ title, property, findData }) {
	const result = findData(['additionalProperty', property])
	if (!result) return <></>
	return (
		<>
			{result.value
				? <div className='mb-4'>
					<h3 className='pb-4 text-lg font-normal'>{title}</h3>
					{result.value.map(({ name, value }, i) => (
						<div key={`${property}_${i}`} className=''>{name ? <span className='font-medium' style={color967}>{name}: </span> : null}{value}</div>
					))}
				</div>
				: <div />}
			{result.image
				? <div className='w-full h-64 px-8 mb-12 bg-center bg-auto md:w-1/2' style={{ backgroundImage: `url(${result.image})` }} />
				: <></>}
		</>
	)
}

const color967 = { color: '#967 ' }


  function HideControl ({ btid, findData }) {
      const [authState, setAuthState] = useState(0) // 0 = inactive, 1 = auth, 2 = fail

      switch (authState) {
          case 0: return (
              <div className='w-full'>
                  <div className='w-full text-center'>
                          <button className='px-8 py-4 my-8 font-semibold tracking-wider text-white uppercase bg-orange-500 hover:bg-orange-400 text-md' onClick={() => setAuthState(1)}>Click For Details</button>
                  </div>
              </div>
          )
        
          case 1: return (

            <div className='container mx-auto'>
                  <div className='flex flex-wrap p-4 bg-white sm:pt-24 rounded-md'>
                  
                  {/* Tag Fingerprint Viewer */}
                  <div className='px-4'>
                      <p className='font-bold'>Please authenticate fingerprinted tag on item with Blocktag app to view authentication protected data.</p>

                      <div className='w-full text-center '>
                              <form className='font-semibold tracking-wider text-white uppercase text-md'
                              action="https://apps.apple.com/us/app/blocktag/id1478833776">
                              <input type="image" src="/demo/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" />
                          </form>
                      </div>
                  </div>
                  
                  {/* Temp placeholder for fingerprinted tag */}
                  {/*<h1 className='px-4 mt-8 mb-4 text-3xl font-hairline'>Authentication Tag </h1>*/}
                  {/*<div className='w-full pb-8 md:px-4'><img className='mx-auto' src='/demo/tag.jpeg' alt='Fingerprinted Tag' /></div>*/}

            </div>
                  
                  
                  
                  
                  
                  {/* Title and metadata  */}
                  <div className='w-full px-4 text-gray-700'>
                      <h4 className='text-xs font-light text-gray-500'><span className='font-semibold'>ID:&nbsp;</span>{btid}</h4>
                      <h1 className='text-4xl font-hairline'>{findData('name')}</h1>
                      <h1 className='text-4xl font-hairline'>{findData('name2')}</h1>
                      <h1 className='text-4xl font-hairline'>{findData('name3')}</h1>
                  </div>
                  
                  
                  {/* Was: Genetics */}
                  <AdditionalPropertySection title='' property='Introduction' findData={findData} />

                  <AdditionalPropertySection title='Structure' property='Structure' findData={findData} />
                  
                  {findData(['subjectOf'])
                      ? <div className='px-4'>
                          <h1 className='mb-4 text-3xl font-hairline'>Certificate</h1>

                          {/* mb-4 text-sm */}
                          <div className='flex flex-wrap text-sm'>
                              {findData(['subjectOf']).map(({ startDate, organizer, name, description, location, image, type }, i) => (
                                                                                                                                       
                                  <div key={`history_${i}`} className='w-full pb-8 md:px-4'>  {/*md:w-1/2*/}
                                      {/*style={{ width: '300px' }}*/}
                                      <img className='mx-auto' src={'data:image/jpeg;base64,' + image} alt='Item' />
                                                                                                                                 
                                  {/* }<div className='flex-none w-32 h-auto overflow-hidden text-center bg-cover rounded-t-none rounded-t rounded-l' style={{ backgroundImage: `url('/demo/face${i + 1}.jpg')` }} title='Event List' /> */}
                                                                                                                                       
                                      {/*bg-gray-200*/}
                                      <div className='flex flex-col justify-between flex-grow p-4 leading-normal rounded-b rounded-r'>
                                          <div className='mb-8'>
                                            {/*<p className='flex items-center text-xs text-gray-600'>{dayjs(startDate).format('MM/YYYY')} </p>*/}
                                              <p className='w-full text-lg font-medium text-gray-900'>{name}</p>
                                              <p className='w-full text-gray-700 font-base'>{description}</p>
                                          </div>
                                          <div className='flex items-center'>
                                        {/*}<img className='w-10 h-10 mr-4 rounded-full' src='/img/jonathan.jpg' alt='Avatar of Jonathan Reinink' />*/}
                                              <div className='text-sm'>
                                                {/*<p className='leading-none text-gray-900'>Captions <a href={organizer.url}>{organizer.name}</a>, {location.address}</p> */}
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                              )) }
                          </div>
                      </div>
                      : <div />}
                  


                  <AdditionalPropertySection title='' property='Certificate' findData={findData} />

                  
                  
                  {findData(['subjectOf2'])
                      ? <div className='px-4'>
                          {/* mb-4 text-sm */}
                          <div className='flex flex-wrap text-sm'>
                              {findData(['subjectOf2']).map(({ startDate, organizer, name, description, location, image, type }, i) => (
                                                                                                                                 
                                  <div key={`history_${i}`} className='w-full pb-8 md:px-4'>
                                  <img className='mx-auto' src={'data:image/jpeg;base64,' + image} alt='Item' />
                                                                                                                                 
                                  {/* }<div className='flex-none w-32 h-auto overflow-hidden text-center bg-cover rounded-t-none rounded-t rounded-l' style={{ backgroundImage: `url('/demo/face${i + 1}.jpg')` }} title='Event List' /> */}
                                      <div className='flex flex-col justify-between flex-grow p-4 leading-normal bg-gray-200 rounded-b rounded-r'>
                                          <div className='mb-8'>
                                            {/*<p className='flex items-center text-xs text-gray-600'>{dayjs(startDate).format('MM/YYYY')} </p>*/}
                                              <p className='w-full text-lg font-medium text-gray-900'>{name}</p>
                                              <p className='w-full text-gray-700 font-base'>{description}</p>
                                          </div>
                                          <div className='flex items-center'>
                                        {/*}<img className='w-10 h-10 mr-4 rounded-full' src='/img/jonathan.jpg' alt='Avatar of Jonathan Reinink' />*/}
                                              <div className='text-sm'>
                                                {/*<p className='leading-none text-gray-900'>Captions <a href={organizer.url}>{organizer.name}</a>, {location.address}</p> */}
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                              )) }
                          </div>
                      </div>
                      : <div />}

                  {/* Without muted parameter, video cannot autoplay */}
                  {/* https://stackoverflow.com/questions/17994666/video-auto-play-is-not-working-in-safari-and-chrome-desktop-browser */}


                  
                  
                  {window.location.href === "https://app.blocktag.com/CNPFjZEG"
                  ?   <div>
                          <h1 className='px-4 mt-8 mb-4 text-3xl font-hairline'>Instrument Sound</h1>
                          <Vimeo
                              id='vid'
                              className='w-full pb-12'
                              video='674119192'
                              autoplay
                              controls={false}
                              loop
                              muted
                              showTitle={false}
                              responsive
                              background
                          />
                          <script> document.getElementById('vid').play(); </script>
                      </div>
                  :   <div />
                  }

              </div>
          )
      }
  }

const ShieldCheck = (<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
	<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z' />
</svg>)
