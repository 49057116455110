export const getCookies = (key) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) {
    try {
      return JSON.parse(parts.pop().split(";").shift());
    } catch (err) {
      return null;
    }
  }
  return null;
};
let cookie = null;
export const checkIfCookieExists = () => {
  cookie = getCookies("blocktagauth");
  if (!cookie) {
    window.location.href = "https://www.blocktag.com/";
  }
};
