import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import '../../assets/css/styles.css'
import '../../assets/css/markdown1.css'
import config from 'config'
import NavBar from '../NavBar'
import Footer from '../Footer'

class Layout extends Component {
	constructor (props) {
		super(props)
		this.state = { isActive: false }
		this.toggleNavbar = this.toggleNavbar.bind(this)
	}

	toggleNavbar () {
		this.setState({ isActive: !this.state.isActive })
	}

	render () {
		return (
			<div className='flex flex-col min-h-screen'>
				<Helmet>
					<title>{config.siteTitle}</title>
					<meta name='description' content={config.siteDescription} />
				</Helmet>
				<NavBar isActive={this.state.isActive} toggleNavbar={() => this.toggleNavbar()} />
				<div className='flex-grow'>
					{this.props.children}
				</div>
				<Footer includePreFooter={this.props.includePreFooter} />
			</div>
		)
	}
}

Layout.defaultProps = {
	includePreFooter: false,
}

Layout.propTypes = {
	includePreFooter: PropTypes.bool,
}

export default Layout
