import React from 'react'
import config from 'config'
import { Link } from 'react-router-dom'
import PreFooter from '../PreFooter'

const Footer = ({ includePreFooter = false }) => {
	return (
		<div className='flex bg-white'>
			<div className='container px-8 mx-auto'>
				{includePreFooter &&
				<PreFooter />}
				<hr className='-mx-12' />
				<footer className='flex items-center h-12 my-4 text-sm font-light text-gray-800'>
					<div className='flex-shrink text-left'>
						{/* <Link className='block inline-block mr-6 hover:underline' to='/faq'>
              FAQ
            </Link>
            <Link className='block inline-block mr-6 hover:underline' to='/media'>
              Media
						</Link> */}
						<Link className='block inline-block mr-6 hover:underline' to='/privacy'>
							Privacy
						</Link>
						<Link className='block inline-block mr-6 hover:underline' to='/tos'>
							Terms of Service
						</Link>
					</div>
					<div className='flex-grow flex-shrink-0 text-right'>
						{config.copyright}
					</div>
				</footer>

			</div>
		</div>
	)
}

export default Footer
