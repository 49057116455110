import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import CryptoSignEth from "../cryptosign/eth";

import { TagDetailQuery } from "../../api";
import CustomModal from "./tag_modal";

export default ({ btid }) => {
  const [editing, setEditing] = useState(false);
  const [editData, setEditData] = useState({
    btid: btid || "",
    hashfn: "",
    payload: "",
    type: "",
    id: "",
  });
  const { loading, error, data } = useQuery(TagDetailQuery, {
    variables: { btid },
  });

  if (loading) return "";
  if (error) return `Error: ${error.message}`;

  const { bevents } = data.tag;

  const handleCloseModal = () => {
    setEditing(false);
  };
  return (
    <div className="w-full px-4 pb-4 bg-white rounded-md">
      <div className="flex justify-between w-full pt-6 ">
        <p className="ml-3">
          Tag {btid} | {bevents.length} Events
        </p>
        {Ellipses}
      </div>

      <div className="mt-6 overflow-x-auto">
        <table className="w-full border-collapse table-fixed">
          <thead>
            <tr
              className="text-sm font-medium text-left text-gray-700 rounded-lg"
              style={{ fontSize: "0.9674rem" }}
            >
              <th className="px-4 py-2" style={{ width: "5rem" }}>
                Created
              </th>
              <th className="px-4 py-2" style={{ width: "5rem" }}>
                Type
              </th>
              <th className="px-4 py-2" style={{ width: "10rem" }}>
                From
              </th>
              <th className="px-4 py-2" style={{ width: "8rem" }}>
                Sig
              </th>
              <th className="px-4 py-2">Data</th>
              <th className="px-4 py-2" style={{ width: "5rem" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-sm font-normal text-gray-700">
            {bevents.map((b) => (
              <BEvent
                key={b.id}
                bevent={b}
                setEditing={setEditing}
                setEditData={setEditData}
                editData={editData}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div
        id="pagination"
        className="flex items-center justify-center w-full pt-4 border-t border-gray-100"
      >
        {ArrowL}
        <p className="mx-2 text-sm leading-relaxed text-blue-600 cursor-pointer hover:text-blue-600">
          1
        </p>
        <p className="mx-2 text-sm leading-relaxed cursor-pointer hover:text-blue-600">
          2
        </p>
        <p className="mx-2 text-sm leading-relaxed cursor-pointer hover:text-blue-600">
          {" "}
          3{" "}
        </p>
        <p className="mx-2 text-sm leading-relaxed cursor-pointer hover:text-blue-600">
          {" "}
          4{" "}
        </p>
        {ArrowR}
      </div>

      <div className="mt-6">
        <CryptoSignEth btid={btid} editData={editData} />
      </div>
      <CustomModal
        isOpen={editing}
        onRequestClose={handleCloseModal}
        editData={editData}
        btid={btid}
        setEditing={setEditing}
      />
    </div>
  );
};

const BEvent = ({
  bevent: { id, ts, type, from, hashfn, sig, payload },
  setEditing,
  setEditData,
  editData,
}) => {
  const [expanded, setExpanded] = useState(false);
  const handleEditTag = () => {
    setEditing(true);
    setEditData({
      ...editData,
      id: id,
      hashfn: hashfn,
      payload: payload,
      type: type,
    });
  };

  return (
    <tr
      key={id}
      className="max-w-full py-10 align-top border-b border-gray-200 group hover:bg-gray-100"
    >
      <td className="px-4 py-4">{ts}</td>
      <td className="px-4 py-4">{type}</td>
      <td className="px-4 py-4 truncate">{from}</td>
      <td className="px-4 py-4 truncate" title={`${hashfn} : ${sig}`}>
        {hashfn.substring(0, 1)}:{sig}
      </td>

      <td className="flex px-4 py-4" onClick={() => setExpanded(!expanded)}>
        <span className="flex-grow truncate">
          {expanded ? (
            <pre>{JSON.stringify(JSON.parse(payload), null, 2)}</pre>
          ) : (
            payload
          )}
        </span>
        <span className="float-right opacity-0 group-hover:opacity-100 transition ease-in duration-75">
          {expanded ? "[-]" : "[+]"}
        </span>
      </td>
      <td className="px-4 py-4">
        <button
          onClick={() => handleEditTag()}
          className="px-4 py-2 font-bold text-white bg-purple-500 rounded shadow hover:bg-purple-400 focus:shadow-outline focus:outline-none"
        >
          Edit
        </button>
      </td>
    </tr>
  );
};

// Icons

const Ellipses = (
  <svg
    width="14"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <circle cx="2.19796" cy="1.80139" r="1.38611" fill="#222222" />
      <circle cx="11.9013" cy="1.80115" r="1.38611" fill="#222222" />
      <circle cx="7.04991" cy="1.80115" r="1.38611" fill="#222222" />
    </g>
  </svg>
);

const ArrowL = (
  <svg
    className="w-6 h-6"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071L13.2929 16.7072C13.6834 17.0977 14.3166 17.0977 14.7071 16.7072C15.0977 16.3167 15.0977 15.6835 14.7071 15.293L11.4142 12L14.7071 8.70712C15.0977 8.31659 15.0977 7.68343 14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12Z"
        fill="#2C2C2C"
      />
    </g>
  </svg>
);

const ArrowR = (
  <svg
    className="w-6 h-6"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 12C15 11.7348 14.8946 11.4804 14.7071 11.2929L10.7071 7.2929C10.3166 6.9024 9.6834 6.9024 9.2929 7.2929C8.9024 7.6834 8.9024 8.3166 9.2929 8.7071L12.5858 12L9.2929 15.2929C8.9024 15.6834 8.9024 16.3166 9.2929 16.7071C9.6834 17.0976 10.3166 17.0976 10.7071 16.7071L14.7071 12.7071C14.8946 12.5196 15 12.2652 15 12Z"
      fill="#18A0FB"
    />
  </svg>
);
